/**
 * External dependencies.
 */
import React from 'react';
import { motion } from 'framer-motion';

/**
 * Internal dependencies.
 */
import SEO from '@blocks/seo/seo';
import PageTransition from '@blocks/page-transition/page-transition';
import { fadeInAnimation } from '@utils/framer-animations';
import Header from '@components/header/header';
import Shell from '@components/shell/shell';

const NotFoundPage = ({ location }) => (
	<div className="wrapper">
		<motion.span
			className="wrapper__title"
			{...fadeInAnimation}
		>
			Not Found
		</motion.span>

		<SEO title="404: Not found" />

		<Header showLargeLogo />

		<main className="main">
			<PageTransition location={location}>
				<Shell>
					<h1>404: Not Found</h1>

					<p>You just hit a page that doesnt&#39;t exist... the sadness.</p>
				</Shell>
			</PageTransition>
		</main>
	</div>
);

export default NotFoundPage;
